import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../../directories/footer";
import config from "../../config/config";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";



const data = [
    {
        imgSrc: "images/icons/quality.png",
        title: "Quality",
        description: "Strict quality benchmarks ensure we connect only the best talent."
    },
    {
        imgSrc: "images/icons/transparency.png",
        title: "Transparency",
        description: "So you know what’s happening at every step of the recruitment process."
    },
    {
        imgSrc: "images/icons/commity.png",
        title: "Commitment",
        description: "So you know what’s happening at every step of the recruitment process."
    },
    {
        imgSrc: "images/icons/speed.png",
        title: "Speed",
        description: "First candidates within hours from our deep candidate network."
    },
    {
        imgSrc: "images/icons/delivery.png",
        title: "Delivery",
        description: "Our commitment to you is that we’ll deliver on what we promise, it’s as simple as that."
    },
    {
        imgSrc: "images/icons/reach.png",
        title: "Reach",
        description: "Expanded candidate pool through multi-channel search."
    }
];

// Function to chunk the data array into groups of 2
const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
    }
    return result;
};

const Employer = () => {
    const chunkedData = chunkArray(data, 2);

    return (
        <>
            <Container>
                <Row className="d-felx align-items-center">
                    <Col md={6}>
                        <div className="hero-section">
                            <h3>Browse as an Employer</h3>
                            <h1>
                                AI powered Talent Matching with <span className="color-text">Hirree</span>
                            </h1>
                            <p>
                                Leverage advanced AI technology with Hirree to receive top candidate recommendations based on detailed resume analysis and job requirements.
                            </p>
                            <Link to={`${config.baseUrl}coming-soon`}>   <Button type="button" className="theme-btn">Sign up Now</Button></Link>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mac-img">
                            <img src="images/mac.svg" alt="mac" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <ScrollAnimation animateIn="fadeIn">
                <section className="mt-0">
                    <Container>
                        <Row className="d-flex align-items-center">
                            <Col md={4}>
                                <div className="recruitment">
                                    <h3>Why we’re leaders in recruitment?</h3>
                                </div>
                            </Col>
                            <Col md={8}>
                                <div className="recui-boxes">
                                    <Row className="mt-5" >
                                        {chunkedData.map((chunk, colIndex) => (
                                            <Col md={4} key={colIndex} className={(colIndex === 0 || colIndex === 2) ? 'mt-md-5 pt-md-5' : ''}>
                                                {chunk.map((item, index) => (
                                                    <div className="outer-box" key={index}>
                                                        <div className="inner-box">
                                                            <div className="icon">
                                                                <img src={item.imgSrc} alt={item.title} />
                                                            </div>
                                                            <h4>{item.title}</h4>
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section className="hiree-right pt-md-5">
                    <Container>
                        <ScrollAnimation animateIn="fadeIn">
                            <h1><span className="color-text ">Hire right. Every time</span></h1>
                            <Row className="pt-5 d-flex align-items-center">
                                <Col md={6}>
                                    <div className="hirree-text">
                                        <h3>AI-Powered Candidate Recommendations</h3>
                                        <p>Receive intelligent candidate recommendations based on advanced resume analysis and specific job requirements. Find the best fit for your openings quickly and efficiently.</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="frames frame1">
                                        <div className="color-box"></div>
                                        <div className="applicants d-flex justify-content-between">
                                            <div><h3>76</h3><p>New Applicants</p></div>
                                            <div><img src="images/prime_user.png" alt="user" /></div>
                                        </div>
                                        <div className="applicant-user">
                                            <img src="images/applicant-card.png" alt="applicant" />
                                        </div>
                                        <div className="purple circle "></div>
                                        <div className="blue circle"></div>

                                    </div>
                                </Col>
                            </Row>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn">
                            <Row className="py-md-5 mt-md-5 mb-5 d-flex align-items-center">
                                <Col md={6}>
                                    <div className="frames frame2">
                                        <div className="color-box"></div>
                                        <div className="applicants d-flex justify-content-between">
                                            <div><h3>76</h3><p>New Applicants</p></div>
                                            <div><img src="images/prime_user.png" alt="user" /></div>
                                        </div>
                                        <div className="applicant-user">
                                            <img src="images/interview-form.png" alt="applicant" className="img1" />
                                        </div>
                                        <div className="img2">
                                            <img src="images/popup.png" alt="popup" />
                                        </div>
                                        <div className="purple circle "></div>
                                        <div className="blue circle"></div>

                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="hirree-text mt-5">
                                        <h3>Integrated Interview Scheduling</h3>
                                        <p>Schedule, manage, and track interviews seamlessly within the platform, ensuring a streamlined experience for both you and the candidates.</p>
                                    </div>
                                </Col>

                            </Row>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeIn">
                            <Row className="py-md-5 d-flex align-items-center mt-5">
                                <Col md={6}>
                                    <div className="hirree-text">
                                        <h3>Customizable Hiring Stages</h3>
                                        <p>Tailor the recruitment process to fit your unique needs, from initial application to final offer.</p>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="window-scrn mac-img">
                                        <img src="images/mac-img.svg" alt="mac" className="pull-right" />
                                    </div>
                                </Col>
                            </Row>
                        </ScrollAnimation>
                    </Container>
                </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
                <section>
                    <Container>
                        <div className="looking">
                            <h2>Looking to Hire?<br /> You are at the right place!</h2>
                            <Link to={`${config.baseUrl}coming-soon`}>  <Button type="button" className="theme-btn">Sign up Now</Button></Link>
                        </div>
                    </Container>
                </section>
            </ScrollAnimation>
                <Footer />
        </>
    )
}
export default Employer;
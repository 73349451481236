import React from "react";
import { Container ,Row,Col,Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import config from "../config/config";
import ScrollAnimation from "react-animate-on-scroll";


const Footer = () =>{
    return(
        <>
         <hr className="mt-0"/>
         <ScrollAnimation animateIn="fadeIn">
    <footer className="p-4">
        <Container>
            <Row>
                <Col md={6}>
                <div className="footer-content">
                    <h2>Ready to work <br/>with us ?</h2>
                    <Button type="button" className="theme-btn bordered-btn">business@hirree.com</Button>
                    <Button type="button" className="theme-btn bordered-btn">hr@hirree.com</Button>
                </div>
                </Col>
                <Col md={6}>
                <div className="address">
                    <p className="text-center">Address :   C-25 Sector 8 Noida-201301, India</p>
                    {/* <p>Phone : 3463536572xxx</p> */}
                </div>
                </Col>
            </Row>
            <hr/>
                <div className="copy-right d-flex justify-content-between">
                    <span>©Copyright 2024. @hirree.com</span>
                    <div>
                        <span className="afterline me-2"><Link to={`${config.baseUrl}privacy-policy`}>Privacy Policy</Link></span>
                        <span><Link to={`${config.baseUrl}terms-and-conditions`}>Terms and Condition</Link></span>
                    </div>
                    {/* <div className="icon">
                        <img src="images/icons/linkedin.png" alt="linkedin"/>
                        <img src="images/icons/facebook.png" alt="facebook"/>

                    </div> */}
                </div>
        </Container>
    </footer>
    </ScrollAnimation>
        </>
    )
}

export default Footer;
import React, { useState, useEffect } from 'react';

const Countdown = () => {
  // Target date September 1, 2024
  const targetDate = new Date('2024-10-12T00:00:00Z');

  const calculateTimeLeft = () => {
    let difference = +targetDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className='counter'>
      {timeLeft.days > 0 ? (
        <>
          <div className='time-box'><h3>{timeLeft.days}</h3> <p>days</p></div>
          <div className='time-box'><h3>{timeLeft.hours}</h3> <p>hours</p></div>
          <div className='time-box'><h3>{timeLeft.minutes}</h3> <p>minutes</p></div>
          <div className='time-box'><h3>{timeLeft.seconds}</h3> <p>seconds</p></div>
        </>
      ) : (
        <div>Countdown finished!</div>
      )}
    </div>
  );
};

export default Countdown;

import React, { Component, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import config from '../config/config';
import Home from '../components/Home/home';
import ComingSoon from '../components/coming soon/comming2';
import PrivacyPolicy from '../components/content page/privacyPolicy';
import TermsConditions from '../components/content page/termsCondition';
const RouterComponent = () => {
    return (<BrowserRouter >
            <div> 
                <Routes>
                    <Route path={`${config.baseUrl}`} element={<Home/>} />
                    <Route path={`${config.baseUrl}coming-soon`} element={<ComingSoon/>} />
                    <Route path={`${config.baseUrl}privacy-policy`} element={<PrivacyPolicy/>} />
                    <Route path={`${config.baseUrl}terms-and-conditions`} element={<TermsConditions/>} />

                </Routes>
            </div>
    </BrowserRouter>
    )
}
export default RouterComponent
import React,{useEffect} from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../../directories/footer";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import config from "../../config/config";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";



const search = [
    { name: 'React' },
    { name: 'Manual Testing' },
    { name: 'UI/UX Designer' },
    { name: 'Automation Test Engineer' },
    { name: 'React' },
    { name: 'Management' }
]
const Candidate = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      }, []);

    return (
        <>
            <Container>
                <Row className="d-flex align-items-center">
                    <Col md={6}>
                        <div className="hero-section">
                            <h3>Browse as a Candidate
                            </h3>
                            <h1>
                                Find Jobs That Fit You Perfectly
                            </h1>
                            <p>
                                Utilize the power of AI with Hirree to find job opportunities that perfectly match your skills and experience.Maximize your chances of landing your dream job.
                            </p>

                            <div className="jon-input">
                                <InputGroup>
                                    <img src="images/search.png" className="search" alt="search" />
                                    <Form.Control type="text" placeholder="Job tittle, Keyword..." />
                                </InputGroup>
                                <InputGroup>
                                    <img src="images/location.png" className="location" alt="location" />
                                    <Form.Control type="text" placeholder="Your Location" />
                                </InputGroup>
                               <Link to={`${config.baseUrl}coming-soon`}> <Button type="button" className="find-job">Find Job</Button></Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mac-img">
                            <img src="images/mac2.svg" alt="mac" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <ScrollAnimation animateIn="fadeIn">
            <section className="popular-search">
                <Container>
                    <Row>
                        <Col md={6}>
                            <p>Popular searches</p>
                            {search.map((item) => (
                                <Button type="button" className="theme-btn bordered-btn me-1 mb-2">
                                    <span className="color-text">{item.name}</span>
                                </Button>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn">
            <section className="mt-0 working-img">
                <img src="images/working.png" />
            </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
            <section className="hiree-right pt-md-5">
                <Container>
                <ScrollAnimation animateIn="fadeIn">
                    <h1><span className="color-text ">Tailored Job Opportunities at Your Fingertips</span></h1>
                    <Row className="pt-5 d-flex align-items-center">
                        <Col md={6}>
                            <div className="hirree-text p-2">
                                <h3>Personalized Job Recommendations</h3>
                                <p>Receive job recommendations specifically tailored to your resume and skills, ensuring you find the best opportunities that match your qualifications.</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="frames frame1">
                                <div className="color-box"></div>

                                <div className="job1 job" >
                                    <img src="images/job1.png" alt="job" />
                                </div>
                                <div className="job job2">
                                    <img src="images/job2.png" alt="job" />
                                </div>
                                <div className="purple circle "></div>
                                <div className="blue circle"></div>

                            </div>
                        </Col>
                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                    <Row className="py-5 mt-md-5 d-flex align-items-center">
                        <Col md={6}>
                            <div className="frames frame2">
                                <div className="color-box"></div>
                                <div className="job inter1">
                                    <img src="images/interview1.png" alt="applicant" className="img1" />
                                </div>
                                <div className="job inter2">
                                    <img src="images/interview2.png" alt="popup" />
                                </div>
                                <div className="purple circle "></div>
                                <div className="blue circle"></div>

                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="hirree-text p-3">
                                <h3>Integrated Interview Scheduling</h3>
                                <p>Schedule, manage, and track interviews seamlessly within the platform, ensuring a streamlined experience for both you and the candidates.</p>
                            </div>
                        </Col>

                    </Row>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeIn">
                    <Row className="py-md-5 d-flex align-items-center">
                        <Col md={6}>
                            <div className="hirree-text">
                                <h3>Real-Time Application Tracking</h3>
                                <p>Keep track of your job applications in real-time. Monitor the status of each application, from submission to interview and offer, all in one place.
                                </p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="frames frame1 d-flex align-items-center">
                                <div className="color-box"></div>
                                <div className="purple circle "></div>
                                <div className="blue circle"></div>
                                <div className="text-center">
                                    <div className="pointer">
                                        <span>Screening</span>
                                    </div>
                                    <div className="pointer light">
                                        <span>Test</span>
                                    </div>
                                    <div className="pointer light">
                                        <span>Interview</span>
                                    </div>
                                    {/* <div className="pointer light">
                                        <span>Interview</span>
                                    </div> */}
                                    <div className="pointer">
                                        <span>HR Round</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    </ScrollAnimation>
                </Container>
            </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
            <section className="hiree-right pt-md-5">
                <Container>
                    <h1><span className="color-text ">How It Works</span></h1>
                    <Row className="pt-md-5">
                        <Col md={4}>
                            <div className="work-line">
                                <div className="icon">
                                    <din className="inner-icon"><img src="images/icons/contact.png" /></din>
                                    <img src="images/line.png" className="line"/>
                                </div>
                                
                                <div className="content">
                                    <h3>Sign up on hirree.com upload your CV & opt in to receive job alerts. </h3>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="work-line">
                                <div className="icon">
                                    <din className="inner-icon"><img src="images/icons/file.png" /></din>
                                    <img src="images/line.png" className="line"/>
                                </div>
                            
                                <div className="content">
                                    <h3>Start browsing the job board & apply for jobs with a click of a button. </h3>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="work-line">
                                <div className="icon">
                                    <din className="inner-icon"><img src="images/icons/rocket.png" /></din>
                                </div>

                                <div className="content">
                                    <h3>Check back in your account to see updates on your applications.</h3>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
            <section>
                <Container>
                    <div className="looking">
                        <h2>Looking for a job opportunity?<br/> You are at the right place!</h2>
                        <Link to={`${config.baseUrl}coming-soon`}>  <Button type="button" className="theme-btn">Sign up Now</Button></Link>
                    </div>
                </Container>
            </section>
            </ScrollAnimation>
            
            <Footer />
        </>
    )
}
export default Candidate;
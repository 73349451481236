import React, { useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import Countdown from "./counter";
import { Link } from "react-router-dom";
import config from "../../config/config";
import Footer from "../../directories/footer";
import axios from "axios";
import { Toaster, resolveValue, toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const ComingSoon = () => {
    const [email, setEmail] = useState("");

    const validationSchema = Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is required'),
    });

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await validationSchema.validate({ email }, { abortEarly: false });
            const response = await axios.post('http://localhost:8000/subscribe', { email });
            if (response.status === 200) {
                toast.success(response.data.message, { duration: 5000 });
                setEmail("");
            } else {
                toast.error("Please enter correct email");
            }
        } catch (err) {
            if (err instanceof Yup.ValidationError) {
                err.errors.forEach(error => toast.error(error));
            } else {
                toast.error("An unexpected error occurred");
                console.log(err);
            }
        }
    }

    return (
        <>
            <Toaster />
            <section className=" comming_page">
                {/* <Container> */}
                    <Row>
                        <Col lg={1}></Col>
                        <Col lg={3} style={{ position: 'relative' }}>
                            <div className=" my-4 comming-soon">
                                <Link to={`${config.baseUrl}`}>
                                <h1 className="logo">
                        <span className="color-text">Hirree</span>
                    </h1>
                                </Link>
                            </div>
                            <div className="coming-content ">
                                <h1>
                                    <span className="color-text">
                                        A platform to connect
                                        Talent with Opportunities.
                                    </span>
                                </h1>
                                {/* <div className="notify">
                                    <h2>Get notified when we get live!</h2>
                                    <Form.Control
                                        type="email"
                                        value={email}
                                        placeholder="Email Id"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Button type="button" onClick={(e) => handleSubmit(e)}>
                                        Subscribe
                                    </Button>
                                </div> */}
                            </div>
                        </Col>
                        <Col lg={8}>
                        {/* <img src="images/coming.png"/> */}
                            <div className="coming-bg">
                                <div className="counter-outer">
                                    <div>
                                        <h2>Arriving in...</h2>
                                        <div className="inner-box">
                                            <Countdown />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                {/* </Container> */}
            </section>
            <Footer />
        </>
    );
}

export default ComingSoon;

import React,{useEffect} from "react";
import { Container, Row ,Col} from "react-bootstrap";
import Header from "../../directories/header";
import Footer from "../../directories/footer";
import data from "./termsData";
const TermsConditions = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
      }, []);
    return (
        <>
            <Header />
            <section className="pt-0">
                <div className="bg-text">
                    <h1 className="logo text-center">
                        <span className="color-text">{data.title}</span>
                    </h1>
                </div>
                <Container>
                    <Row className="pt-md-5 pt-3">
                    <Col md={1}></Col>
                    <Col md={10}>
                    <div className="content-text">
                            {data.description.map((desc, index) => (
                                <p key={index}>{desc}</p>
                            ))}
                            <ol className="ps-4 ol">
                                {data.points.map((point, index) => (
                                    <li key={index}>
                                        <h3>{index+1})  {point.title}</h3>
                                        {point.data.map((item, idx) => (
                                            <div key={idx} className="ms-3">
                                                {item.description.map((descItem, i) => (
                                                    <p key={i}>{descItem}</p>
                                                ))}
                                            </div>
                                        ))}
                                    </li>
                                ))}
                            </ol>
                        </div></Col>
                    <Col md={1}></Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    )
}

export default TermsConditions;

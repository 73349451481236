import React from "react";
import { Button } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import config from "../config/config";
import { Link } from "react-router-dom";



const Header = () => {
    return (
        <>
            <Navbar className=" py-4 mb-md-3">
                <Container>
                    <Navbar.Brand href={`${config.baseUrl}`}> 
                        <h1 className="logo">
                        <span className="color-text">Hirree</span>
                    </h1>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text className="authen">
                            <Link to={`${config.baseUrl}coming-soon`}>  <Button type="button" className="theme-btn bordered-btn"><span className="color-text">Login</span></Button></Link>
                            <Link to={`${config.baseUrl}coming-soon`}> <Button type="button" className="theme-btn">Sign Up</Button></Link>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
export default Header;